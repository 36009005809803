import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../apiConfig';

function QuotationList() {
    const [quotations, setQuotations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalQuotations, setTotalQuotations] = useState(0);
    const token = localStorage.getItem('userToken');

    useEffect(() => {
        const fetchQuotations = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/quotation-list?page=${currentPage}`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
                const quotationData = response.data.data;
                setQuotations(quotationData.data);
                setCurrentPage(quotationData.current_page);
                setLastPage(quotationData.last_page);
                setPerPage(quotationData.per_page);
                setTotalQuotations(quotationData.total);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Failed to fetch quotations', error);
            }
        };

        fetchQuotations();
    }, [currentPage, token]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    return (
        <div>
            <Header />
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    
                                    <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                        <h3 className="fs-5 mb-0">Your Quotations</h3>
                                        <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                            <i className="bi bi-text-indent-left fs-3" />
                                        </button>
                                    </div>
                                    {loading ? (
                                        <p>Loading quotations list...</p>
                                    ) : (
                                        <>
                                            <div className="table-responsive-xxl border-0">
                                                <table className="table mb-0 text-nowrap table-centered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Quotation Number</th>
                                                            <th>Customer Name</th>
                                                            <th>Description</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {quotations.length > 0 ? (
                                                            quotations.map((quotation) => (
                                                                <tr key={quotation.id}>
                                                                    <td className="align-middle border-top-0 fw-semibold text-inherit">
                                                                        <h6>#{quotation.quotation_number}</h6>
                                                                    </td>
                                                                    <td className="align-middle border-top-0 fw-semibold text-inherit">
                                                                        <h6>{quotation.customer.primary_contact_name || 'N/A'}</h6>
                                                                    </td>
                                                                    <td className="align-middle border-top-0 fw-semibold text-inherit">
                                                                        <h6>{quotation.quotation_description}</h6>
                                                                    </td>
                                                                    <td className="align-middle border-top-0 fw-semibold text-inherit">
                                                                        <h6 className="badge bg-warning">{quotation.status.status_title || 'Pending'}</h6>
                                                                    </td>
                                                                    <td className="text-muted align-middle border-top-0">
                                                                        <Link to={`/quotation-details/${quotation.id}`} className="text-inherit">
                                                                            <i className="feather-icon icon-eye" />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">
                                                                    No quotations found.
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>


                                            <div className="d-flex justify-content-between align-items-center mt-4">
                                                <span>Showing {quotations.length} of {totalQuotations} quotations</span>
                                                <nav aria-label="Page navigation">
                                                    <ul className="pagination mb-0">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                                                        </li>
                                                        {[...Array(lastPage)].map((_, index) => (
                                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                                                            </li>
                                                        ))}
                                                        <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                                                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default QuotationList;
