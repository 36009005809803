import React from 'react'
import Header from '../Pages/Header'
import Footer from '../Pages/Footer'
import Banner from './Banner'
import Partner from './Partner'

export default function Home() {
    const zoom = (event) => {
    };
    return (
        <>
            <Header></Header>
            <main>
                <Banner></Banner>
                <div>
                
                    <section className="my-lg-14 my-8">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-6 border-end-lg p-md-4 px-xl-12 text-center">
                                        <div>
                                            <div className="mb-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="currentColor" className="bi bi-clock text-primary" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>
                                            </div>
                                            <h3 className="fs-5 mb-3">Access to Industry-Leading Solutions</h3>
                                            <p className="mb-0">Discover top-tier IT products and solutions tailored to meet your business needs, from cutting-edge software to high-performance hardware.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-6 border-end-lg p-md-4 px-xl-12 text-center">
                                        <div>
                                            <div className="mb-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="currentColor" className="bi bi-gift text-primary" viewBox="0 0 16 16">
                                                    <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z" />
                                                </svg>
                                            </div>
                                            <h3 className="fs-5 mb-3">Streamlined Purchasing Experience</h3>
                                            <p className="mb-0">Enjoy a seamless shopping experience with intuitive navigation, detailed product information, and efficient ordering processes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-6 p-md-4 px-xl-12 text-center">
                                        <div>
                                            <div className="mb-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="currentColor" className="bi bi-box-seam text-primary" viewBox="0 0 16 16">
                                                    <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                                                </svg>
                                            </div>
                                            <h3 className="fs-5 mb-3">Expert Insights and Recommendations</h3>
                                            <p className="mb-0">Make informed decisions with expert advice, comprehensive product details, and personalized recommendations tailored to your requirements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-md-none d-lg-block">
                                    <hr className="mt-8 mb-10" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-lg-14 mt-8">
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-10 col-12">
                                    <div className="row align-items-center mb-14">
                                        <div className="col-md-4">
                                            <div className="ms-xxl-1 me-xxl-1 mb-8 mb-md-0 text-center text-md-start">
                                                <h1 className="mb-6">Discover why leading businesses and satisfied customers trust our solutions</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="ms-xxl-14 me-xxl-15 mb-8 mb-md-0 text-center text-md-start">
                                                <p className="mb-6 lead">We’ll help you achieve your strategic goals — combining the power of people and technology to improve the client experience, increase efficiency and grow revenue. Discover how our Intelligent Technology Solutions™ and broad technical expertise will elevate your business.</p>
                                                <a href="#" className="btn btn-primary mt-2">Explore all of what we do</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="py-14" style={{ backgroundColor: '#124212' }}>
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-10">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-6">
                                            <div className="text-white me-8 mb-12 text-center">
                                                <h1 className="text-white mb-1">V2UMart</h1>
                                                <div className="d-flex justify-content-center">
                                                    <hr className="w-20" />
                                                </div>
                                                <h2 className="text-white mb-4">"Preferred by Retailers. Cherished by Customers"</h2>
                                                <p>V2UMart delivers maximum returns on IT investments.</p>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 row-cols-md-4">
                                            <div className="col mb-4 mb-md-0">
                                                <h3 className="display-5 fw-bold text-white mb-0">500k</h3>
                                                <span className="fs-6 text-white">Retail Partners</span>
                                            </div>
                                            <div className="col mb-4 mb-md-0">
                                                <h3 className="display-5 fw-bold text-white mb-0">4,500+</h3>
                                                <span className="fs-6 text-white">Happy Customers</span>
                                            </div>
                                            <div className="col mb-4 mb-md-0">
                                                <h3 className="display-5 fw-bold text-white mb-0">40k+</h3>
                                                <span className="fs-6 text-white">Industries Served</span>
                                            </div>
                                            <div className="col mb-4 mb-md-0">
                                                <h3 className="display-5 fw-bold text-white mb-0">10+</h3>
                                                <span className="fs-6 text-white">Years of Excellence</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mb-lg-10 mt-lg-14 my-8 py-14 py-md-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-6 text-center">
                                    <h1 className="mb-1">V2U Mart Stories</h1>
                                    <p className="mb-0">Explore our latest insights and updates through our engaging blog posts</p>
                                    <div className="d-flex justify-content-center">
                                        <hr className="w-20" />
                                    </div>
                                </div>
                            </div>
                            <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
                                <div className="col-12 col-lg-6 col-xl-5">
                                    <img className="img-fluid rounded" loading="lazy" src="assets/images/about/about-img1.jpg" alt="About 1" />
                                </div>
                                <div className="col-12 col-lg-6 col-xl-7">
                                    <div className="row justify-content-xl-center">
                                        <div className="col-12 col-xl-11">
                                            <h2 className="mb-3">Who Are We?</h2>
                                            <p className="lead fs-4 text-secondary mb-3">We help people to build incredible brands and superior products. Our perspective is to furnish outstanding captivating services.</p>
                                            <p className="mb-5">We are a fast-growing company, but we have never lost sight of our core values. We believe in collaboration, innovation, and customer satisfaction. We are always looking for new ways to improve our products and services.</p>
                                            <div className="row gy-4 gy-md-0 gx-xxl-5X">
                                                <div className="col-12 col-md-6">
                                                    <div className="d-flex">
                                                        <div className="me-4 text-primary">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <h2 className="h4 mb-3">Versatile Brand</h2>
                                                            <p className="text-secondary mb-0">We are crafting a digital method that subsists life across all mediums.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="d-flex">
                                                        <div className="me-4 text-primary">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" className="bi bi-fire" viewBox="0 0 16 16">
                                                                <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <h2 className="h4 mb-3">Digital Agency</h2>
                                                            <p className="text-secondary mb-0">We believe in innovation by merging primary with elaborate ideas.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="mt-16 mb-lg-14 mb-8">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-6 text-center">
                                    <h1 className="mb-1">Our Blogs</h1>
                                    <p className="mb-0">Explore our latest insights and updates through our engaging blog posts</p>
                                    <div className="d-flex justify-content-center">
                                        <hr className="w-20" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 mb-10">
                                    <div className="row">
                                        <div className="col-12 col-md-4 col-lg-4 mb-10">
                                            <div className="mb-4">
                                                <a href="#!">
                                                    {/* img */}
                                                    <div className="img-zoom">
                                                        <img src="assets/images/blog/blog-img-10.jpg" alt className="img-fluid w-100" />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="mb-3"><a href="#!">Creativity wherever</a></div>
                                            <div>
                                                <h2 className="h5"><a href="#!" className="text-inherit">HP ENVY 13 Laptop</a></h2>
                                                <p>The new HP ENVY 13" Laptop lets you create from anywhere with the ultra-portable design and enjoy immersive visuals with the near-borderless display.</p>
                                                <div className="d-flex justify-content-between text-muted mt-4">
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-10">
                                            <div className="mb-4">
                                                <a href="#!">
                                                    {/* img */}
                                                    <div className="img-zoom">
                                                        <img src="assets/images/blog/blog-img-11.jpg" alt className="img-fluid w-100" />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="mb-3"><a href="#!">Everything families need in a home printer</a></div>
                                            <div>
                                                <h2 className="h5"><a href="#!" className="text-inherit">HP Spectre x360 15 Convertible</a></h2>
                                                <p>The new HP ENVY 13" Laptop lets you create from anywhere with the ultra-portable design and enjoy immersive visuals with the near-borderless display.</p>
                                                <div className="d-flex justify-content-between text-muted mt-4">
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 mb-10">
                                            <div className="mb-4">
                                                <a href="#!">
                                                    {/* img */}
                                                    <div className="img-zoom">
                                                        <img src="assets/images/blog/blog-img-12.jpg" alt className="img-fluid w-100" />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="mb-3"><a href="#!">Here To Make Other Laptops Jealous</a></div>
                                            <div>
                                                <h2 className="h5"><a href="#!" className="text-inherit">HP OMEN 15 Laptop</a></h2>
                                                <p>The new HP ENVY 13" Laptop lets you create from anywhere with the ultra-portable design and enjoy immersive visuals with the near-borderless display.</p>
                                                <div className="d-flex justify-content-between text-muted mt-4">
                                                    <a href="#" className="btn btn-primary">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-dark" style={{ background: 'url(./assets/images/slider/bgpattern-1.png) repeat', backgroundSize: 'auto', backgroundPosition: 'center' }}>
                        <div className="container">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-10">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="text-white mt-8 mt-lg-0">
                                                <span>Subscribe now</span>
                                                <h3 className="h2 text-white my-4">To get the latest updates and exclusive content delivered straight to your inbox</h3>
                                                <p className="text-white-50">Join our email subscription now to get updates on promotions and coupons.</p>
                                                {/* form */}
                                                <form className="row g-3 needs-validation" noValidate>
                                                    <div className="col-6">
                                                        {/* input */}
                                                        <label htmlFor="emailAddress" className="form-label visually-hidden">Email Address</label>
                                                        <input type="email" className="form-control" id="emailAddress" placeholder="Enter Email Address" required />
                                                        <div className="invalid-feedback">Please enter email.</div>
                                                    </div>
                                                    {/* btn */}
                                                    <div className="col-auto">
                                                        <button type="submit" className="btn btn-primary mb-3">Sign Up</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6 py-10">
                                            <div className="text-center">
                                                {/* img */}
                                                <img src="assets/images/slider/subscriber.png" alt className="img-fluid rounded-pill" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Partner></Partner>

            </main>

            <div className="modal fade" id="quickViewModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-8">
                            <div className="position-absolute top-0 end-0 me-3 mt-3">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    {/* img slide */}
                                    <div className="product productModal" id="productModal">
                                        <div className="zoom" onMouseMove={zoom} style={{ backgroundImage: 'url(assets/images/products/product-single-img-1.jpg)' }}>
                                            {/* img */}
                                            <img src="assets/images/products/product-single-img-1.jpg" alt='image' />
                                        </div>
                                        <div>
                                            <div className="zoom" onMouseMove={zoom} style={{ backgroundImage: 'url(assets/images/products/product-single-img-2.jpg)' }}>
                                                {/* img */}
                                                <img src="assets/images/products/product-single-img-2.jpg" alt='image' />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="zoom" onMouseMove={zoom} style={{ backgroundImage: 'url(assets/images/products/product-single-img-3.jpg)' }}>
                                                {/* img */}
                                                <img src="assets/images/products/product-single-img-3.jpg" alt='image' />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="zoom" onMouseMove={zoom} style={{ backgroundImage: 'url(assets/images/products/product-single-img-4.jpg)' }}>
                                                {/* img */}
                                                <img src="assets/images/products/product-single-img-4.jpg" alt='image' />
                                            </div>
                                        </div>
                                    </div>
                                    {/* product tools */}
                                    <div className="product-tools">
                                        <div className="thumbnails row g-3" id="productModalThumbnails">
                                            <div className="col-3">
                                                <div className="thumbnails-img">
                                                    {/* img */}
                                                    <img src="assets/images/products/product-single-img-1.jpg" alt='image' />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="thumbnails-img">
                                                    {/* img */}
                                                    <img src="assets/images/products/product-single-img-2.jpg" alt='image' />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="thumbnails-img">
                                                    {/* img */}
                                                    <img src="assets/images/products/product-single-img-3.jpg" alt='image' />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="thumbnails-img">
                                                    {/* img */}
                                                    <img src="assets/images/products/product-single-img-4.jpg" alt='image' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="ps-lg-8 mt-6 mt-lg-0">
                                        <a href="#!" className="mb-4 d-block">Bakery Biscuits</a>
                                        <h2 className="mb-1 h1">Napolitanke Ljesnjak</h2>
                                        <div className="mb-4">
                                            <small className="text-warning">
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-half" />
                                            </small>
                                            <a href="#" className="ms-2">(30 reviews)</a>
                                        </div>
                                        <div className="fs-4">
                                            <span className="fw-bold text-dark">$32</span>
                                            <span className="text-decoration-line-through text-muted">$35</span>
                                            <span><small className="fs-6 ms-2 text-danger">26% Off</small></span>
                                        </div>
                                        <hr className="my-6" />
                                        <div className="mb-4">
                                            <button type="button" className="btn btn-outline-secondary">250g</button>
                                            <button type="button" className="btn btn-outline-secondary">500g</button>
                                            <button type="button" className="btn btn-outline-secondary">1kg</button>
                                        </div>
                                        <div>
                                            {/* input */}
                                            {/* input */}
                                            <div className="input-group input-spinner">
                                                <input type="button" defaultValue="-" className="button-minus btn btn-sm" data-field="quantity" />
                                                <input type="number" step={1} max={10} defaultValue={1} name="quantity" className="quantity-field form-control-sm form-input" />
                                                <input type="button" defaultValue="+" className="button-plus btn btn-sm" data-field="quantity" />
                                            </div>
                                        </div>
                                        <div className="mt-3 row justify-content-start g-2 align-items-center">
                                            <div className="col-lg-4 col-md-5 col-6 d-grid">
                                                {/* button */}
                                                {/* btn */}
                                                <button type="button" className="btn btn-primary">
                                                    <i className="feather-icon icon-shopping-bag me-2" />
                                                    Add to cart
                                                </button>
                                            </div>
                                            <div className="col-md-4 col-5">
                                                {/* btn */}
                                                <a className="btn btn-light" href="#" data-bs-toggle="tooltip" data-bs-html="true" aria-label="Compare"><i className="bi bi-arrow-left-right" /></a>
                                                <a className="btn btn-light" href="#!" data-bs-toggle="tooltip" data-bs-html="true" aria-label="Wishlist"><i className="feather-icon icon-heart" /></a>
                                            </div>
                                        </div>
                                        <hr className="my-6" />
                                        <div>
                                            <table className="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td>Product Code:</td>
                                                        <td>FBB00255</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Availability:</td>
                                                        <td>In Stock</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Type:</td>
                                                        <td>Fruits</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipping:</td>
                                                        <td>
                                                            <small>
                                                                01 day shipping.
                                                                <span className="text-muted">( Free pickup today)</span>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    )
}
