import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';


export default function Banner() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {

    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/banner-list`); 
        if (response.data.status) {
          setBanners(response.data.data.banners);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <>
      <section className="mt-0 mb-5">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {banners.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0 ? 'true' : 'false'}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
          </div>
          <div className="carousel-inner">
            {banners.map((banner, index) => (
              <div key={banner.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img
                  src={`${BASE_IMAGE_URL}/${banner.image}`}
                  className="d-block w-100 h-100"
                  alt={banner.first_title}
                />
                <div className="carousel-caption d-none d-md-block text-start">
                  <h5 className="text-dark">{banner.first_title}</h5>
                  <h1 className="text-dark">{banner.second_title}</h1>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </>
  );
}
