import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { BASE_URL } from '../apiConfig';

export default function RequestItem() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        products: [{ product_name: '', quantity: '' }],
        other_details: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (['product_name', 'quantity'].includes(name)) {
            const products = [...formData.products];
            products[index][name] = value;
            setFormData({ ...formData, products });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleAddProduct = () => {
        setFormData({
            ...formData,
            products: [...formData.products, { product_name: '', quantity: '' }]
        });
    };

    const handleRemoveProduct = (index) => {
        const products = [...formData.products];
        products.splice(index, 1);
        setFormData({ ...formData, products });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/request-an-item`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.status) {
                toast.success(response.data.message);
                setErrors({});
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    products: [{ product_name: '', quantity: '' }],
                    other_details: ''
                });
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                const serverErrors = error.response.data.error;
                let formattedErrors = {};

                // Format errors for products array
                Object.keys(serverErrors).forEach((key) => {
                    const keys = key.split('.');
                    if (keys[0] === 'products') {
                        if (!formattedErrors['products']) {
                            formattedErrors['products'] = [];
                        }
                        const index = keys[1];
                        const field = keys[2];
                        if (!formattedErrors['products'][index]) {
                            formattedErrors['products'][index] = {};
                        }
                        formattedErrors['products'][index][field] = serverErrors[key];
                    } else {
                        formattedErrors[key] = serverErrors[key];
                    }
                });

                setErrors(formattedErrors);
                toast.error('Please fix the errors and try again.');
            } else {
                toast.error('Something went wrong. Please try again later.');
            }
        }
    };

    return (
        <>
            <Header />
            <ToastContainer />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-12 col-lg-12 order-lg-2 order-1">
                                <div className="mb-lg-9 mb-5 text-center">
                                    <h1 className="mb-1 h2 fw-bold">Request an Item</h1>
                                </div>
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 mt-15">
                                            <p>Fill out the form below to request an item. Please provide as much detail as possible to help us fulfill your request efficiently.</p>
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        id="name"
                                                        name="name"
                                                        placeholder="Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {errors.name && <div className="invalid-feedback">{errors.name[0]}</div>}
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="email"
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email Address"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email[0]}</div>}
                                                </div>
                                                <div className="col-12">
                                                    <input
                                                        type="number"
                                                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    {errors.phone && <div className="invalid-feedback">{errors.phone[0]}</div>}
                                                </div>
                                                {formData.products.map((product, index) => (
                                                    <div className="row g-3" key={index}>
                                                        <div className="col-6">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.products && errors.products[index]?.product_name ? 'is-invalid' : ''}`}
                                                                id={`product_name_${index}`}
                                                                name="product_name"
                                                                placeholder="Product Name"
                                                                value={product.product_name}
                                                                onChange={(e) => handleChange(e, index)}
                                                                required
                                                            />
                                                            {errors.products && errors.products[index]?.product_name && <div className="invalid-feedback">{errors.products[index].product_name[0]}</div>}
                                                        </div>
                                                        <div className="col-4">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.products && errors.products[index]?.quantity ? 'is-invalid' : ''}`}
                                                                id={`quantity_${index}`}
                                                                name="quantity"
                                                                placeholder="Quantity"
                                                                value={product.quantity}
                                                                onChange={(e) => handleChange(e, index)}
                                                                required
                                                            />
                                                            {errors.products && errors.products[index]?.quantity && <div className="invalid-feedback">{errors.products[index].quantity[0]}</div>}
                                                        </div>
                                                        {index > 0 && (
                                                            <div className="col-2">
                                                                <button type="button" className="btn btn-danger" onClick={() => handleRemoveProduct(index)}>
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                                <div className="col-12 mt-3">
                                                    <button type="button" className="btn btn-primary" onClick={handleAddProduct}>
                                                        <i className="bi bi-plus-lg"></i>
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                    <textarea
                                                        className={`form-control ${errors.other_details ? 'is-invalid' : ''}`}
                                                        name="other_details"
                                                        id="other_details"
                                                        placeholder="Enter shipping address, pincode, and other details"
                                                        value={formData.other_details}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.other_details && <div className="invalid-feedback">{errors.other_details[0]}</div>}
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <img src="assets/images/b2b/request_item.jpg" alt="image" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
