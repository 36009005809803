import React from 'react'
import Header from '../Pages/Header'
import Footer from '../Pages/Footer'

function About() {
  return (
    <>
    <Header/>
        <main>
        <section className="mt-lg-14 mt-8">
            {/* container */}
            <div className="container">
            {/* row */}
            <div className="row">
                {/* col */}
                <div className="col-lg-12 col-12">
                <div className="row align-items-center mb-14">
                    <div className="col-md-12">
                    {/* text */}
                    <div className="ms-xxl-14 me-xxl-15 mb-8 mb-md-0 text-center">
                        <h1 className="mb-6">KNOW ABOUT V2UMART</h1>
                        <p className="mb-0 lead">V2UMart is India's first online platform offering IT products, services and solutions to corporate and institutional customers in India. V2UMart offers an extensive range of hardware , software and integrated IT solutions to address enterprise requirements in office automation, mobility, security, cloud, data centre and collaboration. V2UMart's inventory-led model delivers a range of options from leading international OEMs. V2UMart aims to significantly reduce IT procurement costs for its customers by introducing transparency and efficiency in the process. V2UMart helps customers manage the complete IT asset lifecycle through product evaluation, procurement, service, warranty and disposal.</p>
                        <p className="mb-6 mt-3 lead">V2UMart is now a Rs 1 Billion annual revenue business. We currently service the IT requirements of over 900 corporate customers in Delhi NCR region and are expanding our operations nationwide.</p>
                    </div>
                    </div>
                    {/* col */}
                    <div className="col-md-8 mt-5">
                    <img src="./assets/images/b2b/about-imgt01.jpg" width="100%"  alt='image' className="img-fluid rounded" />
                    </div>
                    <div className="col-md-4 mt-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-3">
                        {/* img */}
                        <img src="./assets/images/b2b/about-imgt02.jpg" alt='image' className="img-fluid rounded" />
                        </div>
                        <div className="col-lg-12 col-md-12 mt-3">
                        {/* img */}
                        <img src="./assets/images/b2b/about-imgt03.jpg" alt='image' className="img-fluid rounded" />
                        </div>
                    </div>
                    </div>
                </div>
                {/* row */}
                <div className="row align-items-center mb-14">
                    <div className="col-md-12">
                    {/* text */}
                    <div className="ms-xxl-14 me-xxl-15 mb-8 mb-md-0 text-center">
                        <h1 className="mb-6">OUR PURPOSE</h1>
                        <p className="mb-0 lead">V2UMart delivers technology products and solutions from leading OEMs to manage, grow and transform your business. Build a sustainable tech differentiation for your business with V2UMart. V2UMart offers an extensive range of hardware , software and integrated IT solutions to address enterprise requirements in office automation, mobility, security, cloud, data centre and collaboration.</p>
                        <p className="mb-6 mt-3 lead">V2UMart's inventory-led model delivers a range of options from leading international OEMs. V2UMart aims to significantly reduce IT procurement costs for its customers by introducing transparency and efficiency in the process. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc. V2UMart helps customers manage the complete IT asset lifecycle through product evaluation, procurement, service, warranty and disposal. V2UMart is now a Rs 1 Billion annual revenue business. We currently service the IT requirements of over 900 corporate customers in Delhi NCR region and are expanding our operations nationwide.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="bg-dark text-white">
            <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                <div className="mb-14">
                    {/* heading */}
                    <h2 className="my-14 text-white">Welcome to World of V2UMart</h2>
                </div>
                </div>
                <div className="row mb-14">
                {/* Category items */}
                <div className="col-lg-6 col-md-4 col-6">
                    <div className="text-center mb-10 d-flex align-items-center">
                    <div className="mt-4 text-start mx-4">
                        <h5 className="fs-4 my-2 text-white">Donec vitae sapien ut libero</h5>
                        <p className="dummy-paragraph">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                    {/* Image and title */}
                    <a href="#" className="border border-1 bg-white rounded-circle">
                        <svg width="5em" height="5em" viewBox="0 0 16 16" className="bg-light rounded-circle card-image" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z" />
                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
                        </svg>
                    </a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4 col-6">
                    <div className="text-center mb-10 d-flex align-items-center">
                    {/* Image and title */}
                    <a href="#" className="border border-1 bg-white rounded-circle">
                        <svg width="5em" height="5em" viewBox="0 0 16 16" className="bg-light rounded-circle card-image" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3z" />
                        <path d="M15 7v7.5a1.5 1.5 0 0 1-1.5 1.5H9V7h6zM2.5 16A1.5 1.5 0 0 1 1 14.5V7h6v9H2.5z" />
                        </svg>
                    </a>
                    <div className="mt-4 text-start mx-4">
                        <h5 className="fs-4 my-2 text-white">Duis vitae metus enim</h5>
                        <p className="dummy-paragraph">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4 col-6">
                    <div className="text-center mb-10 d-flex align-items-center">
                    <div className="mt-4 text-start mx-4">
                        <h5 className="fs-4 my-2 text-white">My vitae mi pellentesque congue</h5>
                        <p className="dummy-paragraph">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                    {/* Image and title */}
                    <a href="#" className="border border-1 bg-white rounded-circle">
                        <svg width="5em" height="5em" viewBox="0 0 16 16" className="bg-light rounded-circle card-image" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                        </svg>
                    </a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-4 col-6">
                    <div className="text-center mb-10 d-flex align-items-center">
                    {/* Image and title */}
                    <a href="#" className="border border-1 bg-white rounded-circle">
                        <svg width="5em" height="5em" viewBox="0 0 16 16" className="bg-light rounded-circle card-image" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 0 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.361a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.356a2.5 2.5 0 0 0 2.472-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
                        </svg>
                    </a>
                    <div className="mt-4 text-start mx-4">
                        <h5 className="fs-4 my-2 text-white">Donec vitae sapien ut libero</h5>
                        <p className="dummy-paragraph">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </main>
        <Footer/>
    </>
  )
}

export default About
