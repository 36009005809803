import React, { createContext, useState, useContext } from 'react';

const PriceRangeContext = createContext();

export const usePriceRange = () => {
    return useContext(PriceRangeContext);
};

export const PriceRangeProvider = ({ children }) => {
    const [priceRange, setPriceRange] = useState([0, 10000]);

    return (
        <PriceRangeContext.Provider value={{ priceRange, setPriceRange }}>
            {children}
        </PriceRangeContext.Provider>
    );
};
