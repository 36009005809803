import React, { useState, useEffect } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { BASE_IMAGE_URL } from '../apiConfig';

export default function ComparisonPage() {
    const [comparisonList, setComparisonList] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});

    useEffect(() => {
        const storedComparisonList = JSON.parse(localStorage.getItem('comparisonList')) || [];
        setComparisonList(storedComparisonList);

        // Initialize checked items state based on comparison list
        const initialCheckedItems = storedComparisonList.reduce((acc, item) => {
            acc[item.id] = false;
            return acc;
        }, {});
        setCheckedItems(initialCheckedItems);
    }, []);

    const handleCheckboxChange = (itemId) => {
        setCheckedItems({
            ...checkedItems,
            [itemId]: !checkedItems[itemId],
        });
    };

    const clearComparisonList = () => {
        localStorage.removeItem('comparisonList');
        setComparisonList([]);
        setCheckedItems({});
    };

    const handleRemoveItem = (itemId) => {
        const updatedComparisonList = comparisonList.filter(item => item.id !== itemId);
        setComparisonList(updatedComparisonList);
        localStorage.setItem('comparisonList', JSON.stringify(updatedComparisonList));
        const updatedCheckedItems = { ...checkedItems };
        delete updatedCheckedItems[itemId];
        setCheckedItems(updatedCheckedItems);
    };

    return (
        <>
            <Header />
            <main>
                <section className="mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fw-bold">Compare</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt-6 mb-lg-14 mb-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            {comparisonList.map((item, index) => (
                                                <th key={index}>
                                                    <div className="mb-4">
                                                        <a href="#!">
                                                            <div className="img-zoom">
                                                                {/* Adjust the image size here */}
                                                                <img 
                                                                    src={`${BASE_IMAGE_URL}/${item.primary_image}`} 
                                                                    alt={item.product_name} 
                                                                    className="img-fluid img-medium"  height={200} width={200}
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <h2 className="h5">
                                                            <a href="#!" className="text-inherit">{item.product_name}</a>
                                                        </h2>
                                                        <button className="btn btn-danger btn-sm" onClick={() => handleRemoveItem(item.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 text-light">
                                                                <polyline points="3 6 5 6 21 6" />
                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                <line x1={10} y1={11} x2={10} y2={17} />
                                                                <line x1={14} y1={11} x2={14} y2={17} />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Short Description</td>
                                            {comparisonList.map((item, index) => (
                                                <td key={index}>{item.short_description}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Description</td>
                                            {comparisonList.map((item, index) => (
                                                <td key={index}> <p dangerouslySetInnerHTML={{ __html: item.long_description }} /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>Specifications</td>
                                            {comparisonList.map((item, index) => (
                                                <td key={index}>
                                                    <table className="table table-borderless">
                                                        <tbody>
                                                            {(item.specification || []).map((spec, specIndex) => (
                                                                <tr key={specIndex}>
                                                                    <th>{spec.title}</th>
                                                                    <td>{spec.description}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                                {comparisonList.length > 0 && (
                                    <button className="btn btn-danger" onClick={clearComparisonList}>Clear Comparison List</button>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
