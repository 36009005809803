import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Model from './Model';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { BASE_URL } from '../apiConfig';

function Myorder() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);

    const navigate = useNavigate();

    // Fetch orders based on the current page
    useEffect(() => {
        setLoading(true);
        axios.get(`${BASE_URL}/order-list?page=${currentPage}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then(response => {
                const ordersData = response.data.data.orders;
                setOrders(ordersData.data);
                setCurrentPage(ordersData.current_page);
                setLastPage(ordersData.last_page);
                setPerPage(ordersData.per_page);
                setTotalOrders(ordersData.total);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the orders!", error);
                setLoading(false);
            });
    }, [currentPage]);

    const handleOrderClick = (orderNumber) => {
        navigate(`/order-details/${orderNumber}`);
    };


    const handlePageChange = (page) => {
        if (page >= 1 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                    <h3 className="fs-5 mb-0">Your Orders</h3>
                                    <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                        <i className="bi bi-text-indent-left fs-3" />                                                                                                             
                                    </button>
                                </div>
                                    <div className="table-responsive-xxl border-0">
                                        {loading ? (
                                            <p>Loading orders...</p>
                                        ) : (
                                            <>
                                                <table className="table mb-0 text-nowrap table-centered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Order</th>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                            <th>Amount</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orders.map(order => (
                                                            <tr key={order.id}>
                                                                <td className="align-middle border-top-0" onClick={() => handleOrderClick(order.order_number)}>
                                                                    <a href="#" className="text-inherit">#{order.order_number}</a>
                                                                </td>
                                                                <td className="align-middle border-top-0">{new Date(order.order_date).toLocaleDateString()}</td>
                                                                <td className="align-middle border-top-0">
                                                                    <span className={`badge ${order.status.status_title === 'approve' ? 'bg-success' :
                                                                            order.status.status_title === 'canceled' ? 'bg-danger' :
                                                                                'bg-warning'
                                                                        }`}>
                                                                        {order.status.status_title}
                                                                    </span>
                                                                </td>
                                                                <td className="align-middle border-top-0">₹{order.total_amount.toFixed(2)}</td>
                                                                <td className="text-muted align-middle border-top-0" onClick={() => handleOrderClick(order.order_number)}>
                                                                    <a href="#" className="text-inherit"><i className="feather-icon icon-eye" /></a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {/* Pagination */}
                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                    <span>Showing {orders.length} of {totalOrders} orders</span>
                                                    <nav aria-label="Page navigation">
                                                        <ul className="pagination mb-0">
                                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                                                            </li>
                                                            {[...Array(lastPage)].map((_, index) => (
                                                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                                                                </li>
                                                            ))}
                                                            <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Model />
            <Footer />
        </>
    );
}

export default Myorder;
