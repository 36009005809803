import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { BASE_URL } from '../apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Signin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        if (!password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setLoading(true);
        setErrors({});

        try {
            const response = await axios.post(`${BASE_URL}/customer-login`, { email, password }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const { data } = response;

            if (data.status) {
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('userDetails', JSON.stringify(data.customer));
                localStorage.setItem('isAuthenticated', 'true');

                // toast.success('Login successful!');
                setErrors({});
                toast.success(data.message);

                setTimeout(() => {
                    navigate('/');
                }, 3000);
            } else {
                setErrors(data.error || { login: 'Login failed. Please try again.' });
                toast.error(data.message || 'Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setErrors({ login: 'An error occurred. Please try again.' });
            toast.error(error.response.data.error || error.response.data.message || 'Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
                                <img src="./assets/images/svg-graphics/signin-g.svg" alt='image' className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
                                <div className="mb-lg-9 mb-5">
                                    <h1 className="mb-1 h2 fw-bold">Sign in to <span className="text-success fw-bold">V2UMart</span></h1>
                                    <p>Welcome back to V2UMart! Enter your credentials to access your account.</p>
                                </div>
                                <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 d-grid">
                                            <input
                                                type="email"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                name="email"
                                                placeholder="Email Address"
                                                value={email}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.email && (
                                                <div className="invalid-feedback">
                                                    {Array.isArray(errors.email) ? errors.email.join(', ') : errors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 d-grid mt-5">
                                            <input
                                                type="password"
                                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                id="password"
                                                name="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback">
                                                    {Array.isArray(errors.password) ? errors.password.join(', ') : errors.password}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 d-grid mt-5">
                                        <hr />
                                        <div className="row">
                                            <div className="col-lg-12 d-grid">
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Signing in...' : 'Sign In'}
                                                </button>
                                            </div>
                                            <div className="mt-3 text-center">
                                                <Link to="/forgot-password">Forgot Password?</Link>
                                            </div>
                                            <div className="mt-3 text-center">
                                                Don't Have An Account? <Link to="/signup">Sign Up</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <ToastContainer />
        </>
    );
}
