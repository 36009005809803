import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { BASE_URL } from '../apiConfig';

function WalletHistory() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${BASE_URL}/customer-wallet-history`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
            },
        })
            .then(response => {
                console.log(response.data.data.transactions);
                setTransactions(response.data.data.transactions);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the wallet transaction history!", error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                    <h3 className="fs-5 mb-0">Wallet Transactions</h3>
                                    <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                        <i className="bi bi-text-indent-left fs-3" />
                                    </button>
                                </div>
                            </div>
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    <h2 className="mb-6">Wallet Transaction History</h2>
                                    <div className="table-responsive-xxl border-0">
                                        {loading ? (
                                            <p>Loading transactions...</p>
                                        ) : (
                                            <table className="table mb-0 text-nowrap table-centered">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Order Number</th>
                                                        <th>Description</th>
                                                        <th>Credit Amount</th>
                                                        <th>Debit Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactions.map(transaction => (
                                                        <tr key={transaction.id}>
                                                            <td className="align-middle border-top-0">
                                                                {new Date(transaction.datetime).toLocaleDateString()}
                                                            </td>
                                                            <td className="align-middle border-top-0">
                                                                #{transaction.order_number}
                                                            </td>
                                                            <td className="align-middle border-top-0">
                                                                {transaction.description}
                                                            </td>
                                                            <td className="align-middle border-top-0">
                                                                {transaction.type === 'c' ? (
                                                                    <span className="text-success">
                                                                        ₹{transaction.amount.toFixed(2)}
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </td>
                                                            <td className="align-middle border-top-0">
                                                                {transaction.type === 'd' ? (
                                                                    <span className="text-danger">
                                                                        ₹{transaction.amount.toFixed(2)}
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default WalletHistory;
