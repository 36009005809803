import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';

export default function Partner() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/partner-list`);
        if (response.data.status) {
          setPartners(response.data.data.partners);
        } else {
          throw new Error('Failed to fetch partners');
        }
      } catch (error) {
        console.error('Error fetching partners:', error.message);
      }
    };

    fetchPartners();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <section className="mt-lg-14 my-8">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-6">
            <h3 className="mb-0">Our Partners</h3>
          </div>
        </div>
        <Slider {...settings}>
          {partners.map((partner) => (
            <div key={partner.id}>
              <div className="card card-product">
                <div className="card-body text-center py-8">
                <img src={`${BASE_IMAGE_URL}/${partner.image}`} alt="Partner Logo" className="img-fluid" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <span
      className={`slick-next slick-arrow ${className}`}
      onClick={onClick}
    >
      <i className="feather-icon icon-chevron-right"></i>
    </span>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <span
      className={`slick-prev slick-arrow ${className}`}
      onClick={onClick}
    >
      <i className="feather-icon icon-chevron-left"></i>
    </span>
  );
}
