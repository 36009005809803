import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';


export default function QuotationDetails() {
    const { quotationId } = useParams();
    const [quotationDetails, setQuotationDetails] = useState(null);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('userToken');

    useEffect(() => {
        const fetchQuotationDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/quotation-view/${quotationId}`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('API Response:', response.data.data.quotation);
                setQuotationDetails(response.data.data.quotation);
            } catch (err) {
                console.error('Failed to fetch quotation details:', err);
                setError('Failed to load quotation details');
            }
        };

        fetchQuotationDetails();
    }, [quotationId, token]);

    if (error) return <div>{error}</div>;

    return (
        <div>
            <Header />
            <main>
            <section className="mb-lg-14 mb-8 mt-8">
                    <div className="container">
                        {quotationDetails ? (
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h1 className="thankyou">Quotation Details</h1>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Quotation Information</h1>
                                    <div className="row">
                                    <span className="d-block mb-2">Quotation Number: <strong>#{quotationDetails.quotation_number}</strong></span>

                                    </div>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Address</h1>
                                    <div className="row">
                                        <div className="col-6 mt-2">
                                            <h6>Shipping Address</h6>
                                            <address>
                                                <strong>{quotationDetails.shipping_address.s_address_line1}</strong>
                                                <br />
                                                {quotationDetails.shipping_address.s_address_line2}, {quotationDetails.shipping_address.s_city}, {quotationDetails.shipping_address.s_state}, {quotationDetails.shipping_address.s_country}
                                                <br />
                                                <abbr title="Phone">P: {quotationDetails.shipping_address.s_contact}</abbr>
                                            </address>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <h6>Billing Address</h6>
                                            <address>
                                                <strong>{quotationDetails.billing_address.b_address_line1}</strong>
                                                <br />
                                                {quotationDetails.billing_address.b_address_line2}, {quotationDetails.billing_address.b_city}, {quotationDetails.billing_address.b_state}, {quotationDetails.billing_address.b_country}
                                                <br />
                                                <abbr title="Phone">P: {quotationDetails.billing_address.b_contact}</abbr>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border border-1 mb-4">
                                    <h1 className="mt-2">Products</h1>
                                    <ul className="list-group list-group-flush">
                                        {quotationDetails.quotation_items.length > 0 ? (
                                             quotationDetails.quotation_items.map((item, index) => (
                                                <li key={index} className="list-group-item px-4 py-3">
                                                    <div className="row align-items-center">
                                                        <div className="col-2 col-md-2">
                                                            <img src={`${BASE_IMAGE_URL}/${item.product.primary_image}`} alt="product" className="img-fluid" width={50} />
                                                        </div>
                                                        <div className="col-5 col-md-5">
                                                            <h6 className="mb-0">{item.product.product_name}</h6>
                                                        </div>
                                                        <div className="col-2 col-md-2 text-center text-muted">
                                                            <span>{item.product_qty}</span>
                                                        </div>
                                                        <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                                        {item.product.custom_rates && item.product.custom_rates.length > 0 ? (
                                                            <span className="fw-bold">{item.product.custom_rates[0].custom_rate} INR</span>
                                                        ) : (
                                                            <span className="fw-bold">{item.product.base_price} INR</span>
                                                        )}

                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="list-group-item">No items found.</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="d-flex justify-content-end mt-4 float-right">
                                    <a href="/" className="btn btn-primary">Continue Shopping</a>
                                </div>
                            </div>
                        ) : (
                            <p>Loading order details...</p>
                        )}
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
