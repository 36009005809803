import React, { useState, useEffect } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { useNavigate } from 'react-router-dom';
import { BASE_IMAGE_URL } from '../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from '../../Hooks/useAuth';
import { fetchCartList, updateCartItem, removeCartItem } from '../../Api/productApi';

export default function Cart() {
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();
    const { token } = useAuth();

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const response = await fetchCartList(token);
                const items = response.carts;
                setCartItems(items);
                calculateTotalPrice(items);
            } catch (error) {
                toast.error('Failed to load cart items');
                console.error(error);
            }
        };

        if (token) {
            fetchCartItems();
        }
    }, [token]);

    const calculateTotalPrice = (items) => {
        const total = items.reduce((acc, item) => {
            const price = item.product.customRates ? item.product.customRates : item.product.price;
            return acc + (price * item.product.quantity);
        }, 0);
        setTotalPrice(total);
    };

    const calculateItemTotal = (item) => {
        const price = item.product.customRates ? item.product.customRates : item.product.price;
        return price * item.product.quantity;
    };

    const handleQuantityChange = async (id, newQuantity) => {
        const currentItem = cartItems.find(item => item.id === id);
        const previousQuantity = currentItem ? currentItem.product.quantity : 1;
        
        if (newQuantity <= 0) {
            toast.error('Quantity must be at least 1');
            return;
        }
    
        const updatedItems = cartItems.map(item =>
            item.id === id ? { ...item, product: { ...item.product, quantity: newQuantity } } : item
        );
    
        setCartItems(updatedItems);
        calculateTotalPrice(updatedItems);
    
        try {
            await updateCartItem(id, newQuantity, token);
            toast.success('Cart updated');
        } catch (error) {
            const revertedItems = cartItems.map(item =>
                item.id === id ? { ...item, product: { ...item.product, quantity: previousQuantity } } : item
            );
            setCartItems(revertedItems);
            calculateTotalPrice(revertedItems);
            
            // Display a user-friendly message based on the error
            toast.error(error.response?.data?.message || 'Failed to update cart');
            console.error(error); // Log the full error for debugging
        }
    };
    

    const handleRemove = async (id) => {
        try {
            await removeCartItem(id, token);
            const updatedItems = cartItems.filter(item => item.id !== id);
            setCartItems(updatedItems);
            calculateTotalPrice(updatedItems);
            toast.success('Item removed from cart');
        } catch (error) {
            toast.error('Failed to remove item from cart');
            console.error(error);
        }
    };

    const handleCheckout = () => {
        navigate('/checkout');
    };

    return (
        <>
            <Header />
            <main>
                <section className="mb-lg-14 mb-8 mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="card py-1 border-0 mb-8">
                                    <div>
                                        <h1 className="fw-bold">V2U Mart</h1>
                                        <p className="mb-0">Shopping in 382480</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-7">
                                <div className="py-3">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item py-3 ps-0 border-top">
                                            <div className="row align-items-center">
                                                <div className="col-6 col-md-6 col-lg-7">
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <h6 className="mb-0">Product Name</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 col-md-2 col-lg-2">
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <h6 className="mb-0">Quantity</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 text-lg-end text-start text-md-end col-md-2">
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <h6 className="mb-0">Price</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-1 text-lg-end text-start text-md-end col-md-1">
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <h6 className="mb-0">Total</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {cartItems.map(item => (
                                            <li key={item.id} className="list-group-item py-3 ps-0 border-top">
                                                <div className="row align-items-center">
                                                    <div className="col-6 col-md-6 col-lg-7">
                                                        <div className="d-flex">
                                                            <img src={`${BASE_IMAGE_URL}/${item.product.image}`} alt={item.product.name} className="icon-shape icon-xxl" />
                                                            <div className="ms-3">
                                                                <h6 className="mb-0">{item.product.name}</h6>
                                                                <div className="mt-2 small lh-1">
                                                                    <a href="#!" className="text-decoration-none text-inherit" onClick={() => handleRemove(item.id)}>
                                                                        <span className="me-1 align-text-bottom">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 text-success">
                                                                                <polyline points="3 6 5 6 21 6" />
                                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                                <line x1={10} y1={11} x2={10} y2={17} />
                                                                                <line x1={14} y1={11} x2={14} y2={17} />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-muted">Remove</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 col-md-2 col-lg-2">
                                                        <div className="input-group input-spinner">
                                                            <input
                                                                type="button"
                                                                value="-"
                                                                className="button-minus btn btn-sm"
                                                                onClick={() => handleQuantityChange(item.id, item.product.quantity - 1)}
                                                                disabled={item.product.quantity === 1} // Disable if quantity is 1
                                                            />
                                                            <input
                                                                type="number"
                                                                step={1}
                                                                min={1}
                                                                value={item.product.quantity}
                                                                name="quantity"
                                                                className="quantity-field form-control-sm form-input"
                                                                onChange={(e) => {
                                                                    const newQuantity = parseInt(e.target.value);
                                                                    if (newQuantity > 0) {
                                                                        handleQuantityChange(item.id, newQuantity);
                                                                    } else {
                                                                        toast.error('Quantity must be at least 1');
                                                                    }
                                                                }}
                                                            />
                                                            <input
                                                                type="button"
                                                                value="+"
                                                                className="button-plus btn btn-sm"
                                                                onClick={() => handleQuantityChange(item.id, Math.min(10, item.product.quantity + 1))}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-2 col-md-2 col-lg-2">
                                                        <span className="fw-bold">
                                                            {item.product.customRates ? (
                                                                <>
                                                                    <del className='text-muted'>₹{item.product.price}</del> <br></br> ₹{item.product.customRates}
                                                                </>
                                                            ) : (
                                                                `₹${item.product.price}`
                                                            )}
                                                        </span>
                                                        {item.product.gst || item.product.cgst || item.product.sgst ? (
                                                            <p className="text-muted small">
                                                                incl. GST: ₹{(item.product.gst + item.product.cgst + item.product.sgst).toFixed(2)}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-1 col-md-1">
                                                        <div className="d-flex">
                                                            <div className="ms-3">
                                                                <h6 className="mb-0">₹{calculateItemTotal(item).toFixed(2)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="d-flex justify-content-between mt-4">
                                        <a href="/product" className="btn btn-primary">Continue Shopping</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-5">
                                <div className="mb-5 card mt-6">
                                    <div className="card-body p-6">
                                        <h2 className="h5 mb-4">Summary</h2>
                                        <div className="card mb-2">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="me-auto">
                                                        <div>Item Subtotal</div>
                                                    </div>
                                                    <span>₹{totalPrice.toFixed(2)}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                                    <div className="fw-bold">Subtotal</div>
                                                    <span className="fw-bold">₹{(totalPrice).toFixed(2)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-grid mb-1 mt-4">
                                            <button onClick={handleCheckout} className="btn btn-primary btn-lg d-flex justify-content-between align-items-center">
                                                Go to Checkout
                                                <span className="fw-bold">₹{(totalPrice).toFixed(2)}</span>
                                            </button>
                                        </div>
                                        <p>
                                            <small>
                                                By placing your order, you agree to be bound by the V2U
                                                <a href="/terms" className="text-decoration-underline"> terms & conditions</a>.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="mb-1">We accept</h6>
                                            <img src="/images/visa.png" alt="Visa" className="img-fluid" style={{ width: '80px' }} />
                                        </div>
                                        <p className="text-muted mt-3">Cards accepted.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <ToastContainer />
            <Footer />
        </>
    );
}
