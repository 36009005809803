import React, { useState, useEffect } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Checkout() {
    const [checkoutData, setCheckoutData] = useState(null);
    const [orderNumber, setOrderNumber] = useState(null);
    const [deliveryInstructions, setDeliveryInstructions] = useState("");
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");
    const [paymentMethod, setPaymentMethod] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCheckoutData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/checkout`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
                    },
                });
                console.log(response);

                setCheckoutData(response.data.data);
            } catch (err) {
                toast.error("Failed to load checkout data");
            }
        };

        fetchCheckoutData();
    }, []);

    const changeAddress = () => {
        window.location.href = 'shipping-address';
    }

    const changeBAddress = () => {
        window.location.href = 'address';
    }

    const handlePlaceOrder = async () => {
        try {
            const response = await axios.post(
                `${BASE_URL}/order-store`,
                {
                    billing_address_id: checkoutData.default_billing_address.id,
                    shipping_address_id: checkoutData.default_shipping_address.id,
                    delivery_instructions: deliveryInstructions,
                    purchase_order_number: purchaseOrderNumber,
                    payment_method: paymentMethod,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                    },
                }
            );

            const orderNumber = response.data.data.order_number;
            setOrderNumber(orderNumber);
            toast.success(`Order placed successfully! Order Number: ${orderNumber}`);
            window.location.href = `/order-success/${orderNumber}`;
        } catch (err) {
            console.error("Failed to place order:", err);
            toast.error("Failed to place the order");
        }
    };

    if (!checkoutData) return <div>Loading...</div>;

    const { default_billing_address, default_shipping_address, carts, sub_total, grand_total } = checkoutData;

    return (
        <>
            <Header></Header>
            <main>
                <section className="mb-lg-14 mb-8 mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6 col-md-12">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item py-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href="#" className="fs-5 text-inherit collapsed h4" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                <i className="feather-icon icon-map-pin me-2 text-muted" />
                                                Shiping Address
                                            </a>
                                            <div>
                                                <a href="#" className="btn btn-outline-primary btn-sm me-2" onClick={changeAddress}>Change Address</a>
                                            </div>
                                        </div>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                            <div className="mt-5">
                                                {checkoutData.default_shipping_address ? (
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-6 col-12 mb-4">
                                                            <div className="card card-body p-6">
                                                                <div className="form-check mb-4">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault1" id="homeRadio1" defaultChecked />
                                                                </div>
                                                                <address>
                                                                    <strong>{checkoutData.default_shipping_address.s_address_line1}</strong>
                                                                    <br />
                                                                    {checkoutData.default_shipping_address.s_address_line2}
                                                                    <br />
                                                                    {checkoutData.default_shipping_address.s_city}, {checkoutData.default_shipping_address.s_state}, {checkoutData.default_shipping_address.s_country}
                                                                    <br />
                                                                    <abbr title="Phone">P: {checkoutData.default_shipping_address.s_phone}</abbr>
                                                                </address>
                                                                <span className="text-danger">Default address</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="text-muted">No shipping address added. Please <a href="#" onClick={changeAddress}>choose an address</a>.</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item py-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href="#" className="text-inherit collapsed h5" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                <i className="feather-icon icon-map-pin me-2 text-muted" />
                                                Billing Address
                                            </a>
                                            <div>
                                                <a href="#" className="btn btn-outline-primary btn-sm me-2" onClick={changeBAddress}>Change Address</a>

                                            </div>
                                        </div>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div className="mt-5">
                                                {checkoutData.default_billing_address ? (
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-6 col-12 mb-4">
                                                            <div className="card card-body p-6">
                                                                <div className="form-check mb-4">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id="homeRadio2" defaultChecked />
                                                                </div>
                                                                <address>
                                                                    <strong>{checkoutData.default_billing_address.b_address_line1}</strong>
                                                                    <br />
                                                                    {checkoutData.default_billing_address.b_address_line2}
                                                                    <br />
                                                                    {checkoutData.default_billing_address.b_city}, {checkoutData.default_billing_address.b_state}, {checkoutData.default_billing_address.b_country}
                                                                    <br />
                                                                    <abbr title="Phone">P: {checkoutData.default_billing_address.b_phone}</abbr>
                                                                </address>
                                                                <span className="text-danger">Default address</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="text-muted">No billing address added. Please <a href="#" onClick={changeBAddress}>choose an address</a>.</div>
                                                )}
                                                <div className="mt-5 d-flex justify-content-end">
                                                    <a href="#" className="btn btn-outline-gray-400 text-muted" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        Prev
                                                    </a>
                                                    <a href="#" className="btn btn-primary ms-2" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        Next
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item py-4">
                                        <a href="#" className="text-inherit h5" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            <i className="feather-icon icon-shopping-bag me-2 text-muted" />
                                            Delivery instructions
                                        </a>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div className="mt-5">
                                                <label htmlFor="DeliveryInstructions" className="form-label sr-only">Delivery instructions</label>
                                                <textarea
                                                    className="form-control"
                                                    id="DeliveryInstructions"
                                                    rows={3}
                                                    placeholder="Write delivery instructions"
                                                    value={deliveryInstructions}
                                                    onChange={(e) => setDeliveryInstructions(e.target.value)}
                                                />
                                                <p className="form-text">Add instructions for how you want your order shopped and/or delivered</p>
                                                <label htmlFor="po_number" className="form-label sr-only mt-2">PO Number</label>
                                                <input
                                                    className="form-control"
                                                    id="po_number"
                                                    placeholder="Write purchase Order Number"
                                                    value={purchaseOrderNumber}
                                                    onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                                                />
                                                <div className="mt-5 d-flex justify-content-end">
                                                    <a href="#" className="btn btn-outline-gray-400 text-muted" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        Prev
                                                    </a>
                                                    <a href="#" className="btn btn-primary ms-2" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                        Next
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item py-4">
                                        <a href="#" className="text-inherit h5" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            <i className="feather-icon icon-credit-card me-2 text-muted" />
                                            Payment Method
                                        </a>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div className="mt-5">
                                                <div>
                                                    <div className="card card-bordered shadow-none mb-2">
                                                        <div className="card-body p-6">
                                                            <div className="d-flex">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="paymentMethod"
                                                                        id="creditLimit"
                                                                        value="credit_limit"
                                                                        checked={paymentMethod === "credit_limit"}
                                                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                                                    />
                                                                    <label className="form-check-label ms-2" htmlFor="creditLimit">
                                                                        Payment with Credit Limit
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card card-bordered shadow-none mb-2">
                                                        <div className="card-body p-6">
                                                            <div className="d-flex">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="paymentMethod"
                                                                        id="paypal"
                                                                        value="paypal"
                                                                        checked={paymentMethod === "paypal"}
                                                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                                                    />
                                                                    <label className="form-check-label ms-2" htmlFor="paypal">
                                                                        Payment with Paypal
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-5 d-flex justify-content-end">
                                                        <a href="#" className="btn btn-outline-gray-400 text-muted" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            Prev
                                                        </a>
                                                        <button
                                                            className={`btn ${paymentMethod ? 'btn-primary' : 'btn-secondary'} ms-2`}
                                                            onClick={handlePlaceOrder}
                                                            disabled={!paymentMethod || isLoading}
                                                        >
                                                            {isLoading ? 'Placing Order...' : 'Place Order'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 offset-xl-1 col-xl-4 col-lg-6">
                                <div className="mt-4 mt-lg-0">
                                    <div className="card shadow-sm">
                                        <h5 className="px-6 py-4 bg-transparent mb-0">Order Details</h5>
                                        <ul className="list-group list-group-flush">
                                            {checkoutData.carts.map((item, index) => (
                                                <li key={index} className="list-group-item px-4 py-3">
                                                    <div className="row align-items-center">
                                                        <div className="col-2 col-md-2">
                                                            <img src={`${BASE_IMAGE_URL}/${item.product.primary_image}`} className="img-fluid" />
                                                        </div>
                                                        <div className="col-5 col-md-5">
                                                            <h6 className="mb-0">{item.product.product_name}</h6>
                                                        </div>
                                                        <div className="col-2 col-md-2 text-center text-muted">
                                                            <span>{item.quantity}</span>
                                                        </div>
                                                        <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                                            {item.product.custom_rates && item.product.custom_rates.length > 0 ? (
                                                                <span className="fw-bold">{item.product.custom_rates[0].custom_rate} INR</span>
                                                            ) : (
                                                                <span className="fw-bold">{item.product.base_price} INR</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                            <li className="list-group-item px-4 py-3">
                                                <div className="d-flex align-items-center justify-content-between fw-bold">
                                                    <div>Subtotal</div>
                                                    <div>{checkoutData.sub_total} INR</div>
                                                </div>
                                            </li>
                                            <li className="list-group-item px-4 py-3">
                                                <div className="d-flex align-items-center justify-content-between fw-bold">
                                                    <div>CGST</div>
                                                    <div>{checkoutData.cgst_amount} INR</div>
                                                </div>
                                            </li>
                                            <li className="list-group-item px-4 py-3">
                                                <div className="d-flex align-items-center justify-content-between fw-bold">
                                                    <div>SGST</div>
                                                    <div>{checkoutData.sgst_amount} INR</div>
                                                </div>
                                            </li>
                                            <li className="list-group-item px-4 py-3">
                                                <div className="d-flex align-items-center justify-content-between fw-bold">
                                                    <div>IGST</div>
                                                    <div>{checkoutData.igst_amount} INR</div>
                                                </div>
                                            </li>
                                            <li className="list-group-item px-4 py-3">
                                                <div className="d-flex align-items-center justify-content-between fw-bold">
                                                    <div>Grand Total</div>
                                                    <div>{checkoutData.grand_total} INR</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* All address model */}
            <div className="modal fade" id="allAddressModal" tabIndex={-1} aria-labelledby="allAddressModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body p-6">
                            <div className="d-flex justify-content-between mb-5">
                                <div>
                                    <h5 className="h6 mb-1" id="allAddressModalLabel">All  Address</h5>
                                </div>
                                <div>

                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-12">
                                    <div className="card card-body p-6">
                                        <div className="form-check mb-4">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" defaultChecked />
                                            <label className="form-check-label text-dark" htmlFor="homeRadio">Home</label>
                                        </div>
                                        <address>
                                            <strong>Jitu Chauhan</strong><br />
                                            4450 North Avenue Oakland,
                                            Nebraska, United States,<br />
                                            <abbr title="Phone">P: 402-776-1106</abbr>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card card-body p-6">
                                        <div className="form-check mb-4">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" defaultChecked />
                                            <label className="form-check-label text-dark" htmlFor="homeRadio">Home</label>
                                        </div>
                                        <address>
                                            <strong>Jitu Chauhan</strong><br />
                                            4450 North Avenue Oakland,
                                            Nebraska, United States,<br />
                                            <abbr title="Phone">P: 402-776-1106</abbr>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card card-body p-6">
                                        <div className="form-check mb-4">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" defaultChecked />
                                            <label className="form-check-label text-dark" htmlFor="homeRadio">Home</label>
                                        </div>
                                        <address>
                                            <strong>Jitu Chauhan</strong><br />
                                            4450 North Avenue Oakland,
                                            Nebraska, United States,<br />
                                            <abbr title="Phone">P: 402-776-1106</abbr>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button type="button" className="btn btn-outline-primary me-2" data-bs-dismiss="modal">Cancel</button>
                                    <button className="btn btn-primary" type="button">Select Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer></Footer>
        </>
    );
}
