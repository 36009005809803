// src/hooks/useAuth.js
import { useEffect, useState } from 'react';

export default function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerCreditLimit, setCustomerCreditLimit] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [userDetails, setuserDetails] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('userToken');
        const userDetails = localStorage.getItem('userDetails');
        setToken(token);
        setuserDetails(userDetails);

        if (token && userDetails) {
            const details = JSON.parse(userDetails);
            setIsAuthenticated(true);
            setCustomerName(details.primary_contact_name || details.company_name);
            setCustomerCreditLimit(details.credit_limit || details.credit_limit);
            setCustomerEmail(details.email);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    return { isAuthenticated, customerName, customerEmail ,token ,userDetails,customerCreditLimit};
}
