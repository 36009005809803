// Loader.js
import React from 'react';

const Loader = () => {
    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    };

    const spinnerStyle = {
        border: '8px solid #f3f3f3',
        borderTop: '8px solid green',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 1s linear infinite',
    };

    return (
        <>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
            <div style={loaderStyle}>
                <div style={spinnerStyle}></div>
            </div>
        </>
    );
};

export default Loader;
