import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchsingleproduct, fetchRelatedProducts, addToCart } from '../../Api/productApi';
import useAuth from '../../Hooks/useAuth';
import { BASE_IMAGE_URL } from '../apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function RelatedProducts() {
    const [product, setProduct] = useState(null);
    const { productSlug } = useParams();
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [loading, setLoading] = useState(true); 
    const { isAuthenticated , token} = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const fetchedProduct = await fetchsingleproduct(productSlug);
                console.log(fetchedProduct);
                
                setProduct(fetchedProduct);
                if (fetchedProduct && fetchedProduct.images.length > 0) {

                }
                const fetchedRelatedProducts = await fetchRelatedProducts(productSlug , token);
                setRelatedProducts(fetchedRelatedProducts);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProductData();
        setLoading(false);

    }, [productSlug , token]);

    const handleAddToCart = async (relatedProduct) => {
        if (!isAuthenticated) {
            toast.error('You must be logged in to add to cart.');
            setTimeout(() => {
                navigate('/signin');
            }, 1000);
            return;
        }
        const quantity = 1;
        const cartItem = {
            product_id: relatedProduct.id,
            quantity
        };

        try {
            await addToCart(cartItem, token);
            setIsAddedToCart(true);
            toast.success('Product added to cart successfully');
            navigate('/cart');
        } catch (error) {
            toast.error('Failed to add product to cart.');
        }
    };
    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <>
            <section className="my-lg-14 my-14">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Related Items</h3>
                        </div>
                    </div>
                    <div className="row g-4 row-cols-lg-4 row-cols-2 row-cols-md-2 mt-2">
                        {relatedProducts.map((relatedProduct) => (
                            <div className="col" key={relatedProduct.product_slug}>
                                <div className="card card-product">
                                    <div className="card-body">
                                        <div className="text-center position-relative">
                                            <div className="position-absolute top-0 start-0">
                                            {relatedProduct.average_rating >= 3 ? (
                                                <span className="badge bg-warning text-dark"> <i className="bi bi-star-fill text-dark me-1" />{relatedProduct.average_rating}</span>
                                            ) : (
                                               <p></p>
                                            )}
                                            </div>
                                            <a href={`/product/${relatedProduct.product_slug}`}>
                                                <img src={`${BASE_IMAGE_URL}/${relatedProduct.primary_image}`} alt={relatedProduct.product_name} className="mb-3 img-fluid" />
                                            </a>

                                        </div>
                                        <div className="text-small mb-1">
                                            <a href="#!" className="text-decoration-none text-muted"><small>{relatedProduct.category}</small></a>
                                        </div>
                                        <h2 className="fs-6"><a href={`/product/${relatedProduct.product_slug}`} className="text-inherit text-decoration-none">{relatedProduct.product_name}</a></h2>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            {isAuthenticated ? (
                                                <>
                                                    <div>
                                                        {relatedProduct.custom_rates && relatedProduct.custom_rates.length > 0 ? (
                                                            <div className="mt-1">
                                                                <p className='text-small' style={{ fontSize: '0.7rem' }}>(This Price Only for you By Price List)</p>
                                                                <span className='text-dark'>Price: ₹{relatedProduct.custom_rates[0].custom_rate}</span>
                                                                <span className="text-decoration-line-through text-muted ms-2">
                                                                    ₹{relatedProduct.discount_price}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="mt-1">
                                                                <span className="fw-bold text-dark">
                                                                    ₹{relatedProduct.discount_price || relatedProduct.base_price}
                                                                </span>
                                                                {relatedProduct.discount_price && relatedProduct.discount_price < relatedProduct.base_price && (
                                                                    <>
                                                                        <span className="text-decoration-line-through text-muted ms-2">
                                                                            ₹{relatedProduct.base_price}
                                                                        </span>

                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    <p className="mt-4">Please <a href="/login">log in</a> to see price.</p>
                                                </div>
                                            )}
                                            <div>
                                                {relatedProduct.is_in_cart ? (
                                                    <a className="btn btn-outline-primary btn-sm" onClick={() => navigate('/cart')}>
                                                        <i className="bi bi-cart me-2"></i> View
                                                    </a>
                                                ) : (
                                                    <a className="btn btn-primary btn-sm" onClick={() => handleAddToCart(product)}>
                                                        <i className="bi bi-cart me-2"></i> Add
                                                    </a>
                                                )}
                                                {/* <a href="#!" className="btn btn-primary btn-sm" onClick={() => handleAddToCart(relatedProduct)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus">
                                                        <line x1={12} y1={5} x2={12} y2={19} />
                                                        <line x1={5} y1={12} x2={19} y2={12} />
                                                    </svg>
                                                    Add
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default RelatedProducts
