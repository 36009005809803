import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../Pages/Footer';
import { BASE_URL , Website_url} from '../apiConfig';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { token } = useParams();


    useEffect(() => {
        // Fetch email from localStorage
        const storedEmail = localStorage.getItem('resetEmail');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            toast.error('No email found for reset.');
            navigate('/forgot-password');
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') setPassword(value);
        if (name === 'confirmPassword') setConfirmPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        setLoading(true);
        try {
            console.log(token);

            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            formData.append('password_confirmation', confirmPassword);
            formData.append('token', token);

            const response = await axios.post(`${BASE_URL}/submit-reset-password-form`, formData, {
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.data.status) {
                toast.success('Password reset successful!');
                setTimeout(() => {
                    navigate('/signin');
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="border-bottom shadow-sm">
                <nav className="navbar navbar-light py-2">
                    <div className="container justify-content-center justify-content-lg-between">
                        <a className="navbar-brand" href="/">
                            <img src="../assets/images/b2b/logo.png" alt="v2u"  className="d-inline-block align-text-top" />
                        </a>
                    </div>
                </nav>
            </div>

            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
                                <img src="../assets/images/svg-graphics/fp-g.svg" alt="" className="img-fluid" />
                            </div>
                            <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1 d-flex align-items-center">
                                <div>
                                    <div className="mb-lg-9 mb-5">
                                        <h1 className="mb-2 h2 fw-bold">Reset Password</h1>
                                        <p>Enter a new password to reset your account.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={email}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="New Password"
                                                    name="password"
                                                    value={password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="col-12 d-grid gap-2">
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Resetting...' : 'Reset Password'}
                                                </button>
                                                <a href="/signin" className="btn btn-light">Back</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
<Footer></Footer>
            <ToastContainer />
        </>
    );
}
