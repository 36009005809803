import React, { useState, useEffect } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../apiConfig';
import useAuth from '../../Hooks/useAuth';

const QuoteRequest = () => {
    const [quoteRequests, setQuoteRequests] = useState([]);
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        description: '', 
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useAuth();

    useEffect(() => {
        const storedRequests = JSON.parse(localStorage.getItem('quoteRequests')) || [];
        setQuoteRequests(storedRequests);

        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails) {
            setForm({
                name: userDetails.primary_contact_name || '',
                email: userDetails.email || '',
                phone: userDetails.phone || '',
                description: '',
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleQuantityChange = (index, delta) => {
        const updatedRequests = [...quoteRequests];
        updatedRequests[index].quantity = Math.max(1, updatedRequests[index].quantity + delta);
        localStorage.setItem('quoteRequests', JSON.stringify(updatedRequests));
        setQuoteRequests(updatedRequests);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BASE_URL}/request-quotation`, {
                product_id: quoteRequests.map(request => request.id),
                quantity: quoteRequests.map(request => request.quantity),
                quotation_description: form.description,
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.message) {
                toast.success('Quotation submitted successfully');
                localStorage.removeItem('quoteRequests');
                setQuoteRequests([]);
                setForm({
                    name: '',
                    email: '',
                    phone: '',
                    description: '',
                });
                setErrors({});
            } else {
                setErrors(response.data.message || {});
                toast.error('Error in quotation submission');
            }
        } catch (error) {
            console.error('Error response:', error);
            setErrors(error || {});
            toast.error('Error submitting quotation');
        }
    };

    const handleDownload = async () => {
        setIsLoading(true); 
    
        try {
            const productIds = quoteRequests.map(request => request.id).join(',');
            const quantities = quoteRequests.map(request => request.quantity).join(',');
            
            const response = await axios.post(`${BASE_URL}/quotation-pdf`,
                new URLSearchParams({ productId: productIds, quantity: quantities }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
    
            if (response && response.data && response.data.fileUrl) {
                const newTab = window.open();
                newTab.location.href = response.data.fileUrl;
            } else {
                toast.error('Error generating quotation. No file URL returned.');
            }
        } catch (error) {
            console.error('Error response:', error.response ? error.response.data : error.message);
            toast.error(error.response?.data?.error || 'Error generating quotation');
        } finally {
            setIsLoading(false); 
        } 
    };
    

    const handleRemove = (index) => {
        const updatedRequests = quoteRequests.filter((_, i) => i !== index);
        localStorage.setItem('quoteRequests', JSON.stringify(updatedRequests));
        setQuoteRequests(updatedRequests);
    };

    const hasCustomRate = quoteRequests.some(request => request.customRate !== null);

    return (
        <>
            <Header />
            <main>
                <div className="container mt-8 pb-10">
                    <h2>Quote Request List</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quoteRequests.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                        <span
                                            onClick={() => handleQuantityChange(index, -1)}
                                            style={{ cursor: 'pointer', color: 'red', fontSize: '20px', marginRight: '8px' }}
                                        >
                                            <i className="bi bi-dash-square-fill"></i>
                                        </span>
                                        {item.quantity}
                                        <span
                                            onClick={() => handleQuantityChange(index, 1)}
                                            style={{ cursor: 'pointer', color: 'green', fontSize: '20px', marginLeft: '8px' }}
                                        >
                                            <i className="bi bi-plus-square-fill"></i>
                                        </span>
                                    </td>
                                    <td>₹{item.price}</td>
                                    <td>₹{item.price * item.quantity}</td>
                                    <td>
                                        <button onClick={() => handleRemove(index)} className="btn btn-danger">
                                            <i className="bi bi-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                value={form.name}
                                onChange={handleChange}
                            />
                            {errors.name && <div className="text-danger">{errors.name.join(', ')}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                value={form.email}
                                onChange={handleChange}
                            />
                            {errors.email && <div className="text-danger">{errors.email.join(', ')}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={form.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <div className="text-danger">{errors.phone.join(', ')}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea
                                id="description"
                                name="description"
                                className="form-control"
                                rows="3"
                                value={form.description}
                                onChange={handleChange}
                            ></textarea>
                            {errors.description && <div className="text-danger">{errors.description.join(', ')}</div>}
                        </div>
                        <div className="mt-3">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary me-2"
                                disabled={hasCustomRate}
                            >
                                Submit Request
                            </button>
                            <button
                                type="button"
                                onClick={handleDownload}
                                className="btn btn-outline-primary"
                            >
                                {isLoading ? 'Generating...' : 'Download Quotation'}
                            </button>
                        </div>
                    </form>
                    <ToastContainer />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default QuoteRequest;
