import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { usePriceRange } from '../../Contexts/PriceRangeContext';
import useAuth from '../../Hooks/useAuth';
import { fetchCategories, fetchProducts } from '../../Api/productApi';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function Aside({ minPrice, maxPrice, brands }) {
    const query = useQuery();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const { priceRange, setPriceRange } = usePriceRange();
    const [sliderValue, setSliderValue] = useState([minPrice, maxPrice]);
    const sliderRef = useRef(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const { isAuthenticated } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getCategories = async () => {
            try {
                const data = await fetchCategories();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        getCategories();
        setLoading(false);
    }, []);

    useEffect(() => {
        const initialMin = query.get('min_price') ? Number(query.get('min_price')) : minPrice;
        const initialMax = query.get('max_price') ? Number(query.get('max_price')) : maxPrice;
        setSliderValue([initialMin, initialMax]);

        if (sliderRef.current && !loading) {
            noUiSlider.create(sliderRef.current, {
                start: [initialMin, initialMax],
                connect: true,
                range: {
                    min: minPrice,
                    max: maxPrice
                },
                step: 100,
                format: {
                    to: (value) => `₹${Math.round(value)}`,
                    from: (value) => Number(value.replace('₹', ''))
                }
            });

            sliderRef.current.noUiSlider.on('update', (values) => {
                const [min, max] = values.map(value => Number(value.replace('₹', '')));
                setSliderValue([min, max]);
                setPriceRange([min, max]);

                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set('min_price', min);
                queryParams.set('max_price', max);
            });
        }

        return () => {
            if (sliderRef.current && !loading) {
                sliderRef.current.noUiSlider.destroy();
            }
        };
    }, [minPrice, maxPrice, navigate, setPriceRange, loading]);

    const handleCategoryClick = (categorySlug) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('category', categorySlug);
        queryParams.delete('subcategory');
        navigate(`?${queryParams.toString()}`);
    };

    const handleBrandChange = (brand) => {
        const updatedBrands = selectedBrands.includes(brand)
            ? selectedBrands.filter(b => b !== brand)
            : [...selectedBrands, brand];
        setSelectedBrands(updatedBrands);

        const queryParams = new URLSearchParams(window.location.search);
        if (updatedBrands.length > 0) {
            queryParams.set('brands', updatedBrands.join(','));
        } else {
            queryParams.delete('brands');
        }
        navigate(`?${queryParams.toString()}`);
    };

    const renderSubCategories = (subCategories) => (
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {subCategories.map(sub => (
                <li key={sub.id} style={{ marginBottom: '0.5rem' }}>
                    <Link to={`/shop?category=${query.get('category')}&subcategory=${sub.slug}`}
                        style={{ textDecoration: 'none', color: '#0aad0a' }}
                    >
                        {sub.title}
                    </Link>
                </li>
            ))}
        </ul>
    );

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <aside className="col-lg-3 col-md-4 mb-6 mb-md-0">
            <div
                className="offcanvas offcanvas-start offcanvas-collapse w-md-50"
                tabIndex={-1}
                id="offcanvasCategory"
                aria-labelledby="offcanvasCategoryLabel"
                style={{ backgroundColor: '#f8f9fa', padding: '1rem' }}
            >
                <div className="offcanvas-header d-lg-none">
                    <h5 className="offcanvas-title" id="offcanvasCategoryLabel">Filter</h5>
                    <button type="  button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body ps-lg-2 pt-lg-0">
                    <div className="mb-8">
                        <h5 className="mb-3">Categories</h5>
                        <ul className="nav nav-category" id="categoryCollapseMenu">
                            {categories.map((category) => (
                                <li className="nav-item border-bottom w-100" key={category.id}>
                                    <a
                                        href="#"
                                        className="nav-link collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#categoryFlush${category.id}`}
                                        aria-expanded="false"
                                        aria-controls={`categoryFlush${category.id}`}
                                        onClick={() => handleCategoryClick(category.slug)}
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        {category.title}
                                        <i className="feather-icon icon-chevron-right" />
                                    </a>
                                    <div
                                        id={`categoryFlush${category.id}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#categoryCollapseMenu"
                                    >
                                        <div>
                                            {renderSubCategories(category.sub_categories)}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {isAuthenticated ? (
                        <div className="mb-8">
                            <h5 className="mb-3">Price</h5>
                            <div>
                                <div id="price-range-slider" ref={sliderRef}></div>
                                <div className="price-range-value">
                                    <span>₹{sliderValue[0]}</span> - <span>₹{sliderValue[1]}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p></p>
                    )}
                    <div className="mb-8">
                        <h5 className="mb-3">Brand</h5>
                        {brands.map((brand, index) => (
                            <div className="form-check mb-2" key={index}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`brand${index}`}
                                    value={brand}
                                    checked={selectedBrands.includes(brand)}
                                    onChange={() => handleBrandChange(brand)}
                                />
                                <label className="form-check-label" htmlFor={`brand${index}`}>{brand}</label>
                            </div>
                        ))}
                    </div>

                    <div className="mb-8">
                        <h5 className="mb-3">Rating</h5>
                        <div>
                            {[5, 4, 3, 2, 1].map((rating) => (
                                <div className="form-check mb-2" key={rating}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`rating${rating}`}
                                    />
                                    <label className="form-check-label" htmlFor={`rating${rating}`}>
                                        {[...Array(rating)].map((_, i) => (
                                            <i key={i} className="bi bi-star-fill text-warning" />
                                        ))}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Aside;
