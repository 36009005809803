import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null,
    customer: null,
    role: null,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        registerSuccess: (state, action) => {
            state.token = action.payload.token;
            state.customer = action.payload.customer;
            state.role = action.payload.role;
            state.error = null;
        },
        registerFailure: (state, action) => {
            state.error = action.payload.error;
        },
        logout: (state) => {
            state.token = null;
            state.customer = null;
            state.role = null;
            state.error = null;
        },
        updateUserDetails: (state, action) => {
            state.customer = { ...state.customer, ...action.payload };
        },
    },
});

export const { registerSuccess, registerFailure, logout, updateUserDetails } = authSlice.actions;
export default authSlice.reducer;
