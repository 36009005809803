import React from 'react'

function Model() {
    return (
        <>
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasAccount" aria-labelledby="offcanvasAccountLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasAccountLabel">Offcanvas</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <ul className="nav flex-column nav-pills nav-pills-dark">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/myorder">
                                <i className="feather-icon icon-shopping-bag me-2" />
                                Your Orders
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/setting">
                                <i className="feather-icon icon-settings me-2" />
                                Settings
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/address">
                                <i className="feather-icon icon-map-pin me-2" />
                                Address
                            </a>
                        </li>
                    </ul>
                    <hr className="my-6" />
                    <div>
                        <ul className="nav flex-column nav-pills nav-pills-dark">
                            <li className="nav-item">
                                <a className="nav-link" href="">
                                    <i className="feather-icon icon-log-out me-2" />
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Model
