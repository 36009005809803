import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Aside() {
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    const handleLogout = () => {
        axios.post(`${BASE_URL}/customer-logout`, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then(response => {
            if (response.data.status) {
                localStorage.setItem('isAuthenticated', 'false');
                localStorage.removeItem('userToken');
                localStorage.removeItem('userDetails');
                localStorage.removeItem('quoteRequests');
                setIsAuthenticated(false);
                setCustomerName('');
                setCustomerEmail('');
                
                toast.success('Logout successful!');

                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        })
        .catch(error => {
            console.error('Error logging out:', error);
        });
    };
    return (
        <>
            <div className="col-lg-3 col-md-4 col-12 border-end d-none d-md-block">
                <div className="pt-10 pe-lg-10">
                    <ul className="nav flex-column nav-pills nav-pills-dark">
                        <li className="nav-item">
                            {/* nav link */}
                            <a className={`nav-link ${path === '/myorder' ? 'active' : ''}`} aria-current="page" href="/myorder">
                                <i className="feather-icon icon-shopping-bag me-2" />
                                Your Orders
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${path === '/setting' ? 'active' : ''}`} href="/setting">
                                <i className="feather-icon icon-settings me-2" />
                                Settings
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${path === '/shipping-address' ? 'active' : ''}`} href="/shipping-address">
                                <i className="feather-icon icon-map-pin me-2" />
                                Shiping Address
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${path === '/address' ? 'active' : ''}`} href="/address">
                                <i className="feather-icon icon-map-pin me-2" />
                                Billing Address
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${path === '/quotationlist' ? 'active' : ''}`} href="/quotationlist">
                                <i className="feather-icon icon-map-pin me-2" />
                                Quotation Request List
                            </a>
                        </li>
                        <li className="nav-item">
                            <hr />
                        </li>
                        <li className="nav-item">
                            <button onClick={handleLogout} className="nav-link">
                                <i className="feather-icon icon-log-out me-2" />
                                Log out
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasAccount" aria-labelledby="offcanvasAccountLabel">
                {/* offcanvas header */}
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasAccountLabel">My Account</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                {/* offcanvas body */}
                <div className="offcanvas-body">
                    <ul className="nav flex-column nav-pills nav-pills-dark">
                        {/* nav item */}
                        <li className="nav-item">
                            <a className={`nav-link ${path === '/myorder' ? 'active' : ''}`} aria-current="page" href="/myorder">
                                <i className="feather-icon icon-shopping-bag me-2" />
                                Your Orders
                            </a>
                        </li>
                        {/* nav item */}
                        <li className="nav-item">
                        <a className={`nav-link ${path === '/setting' ? 'active' : ''}`} href="/setting">
                                <i className="feather-icon icon-settings me-2" />
                                Settings
                            </a>
                        </li>
                        {/* nav item */}
                        <li className="nav-item">
                        <a className={`nav-link ${path === '/shipping-address' ? 'active' : ''}`} href="/shipping-address">
                                <i className="feather-icon icon-map-pin me-2" />
                                Shiping Address
                            </a>
                        </li>
                        <li className="nav-item">
                        <a className={`nav-link ${path === '/address' ? 'active' : ''}`} href="/address">
                                <i className="feather-icon icon-map-pin me-2" />
                                Billing Address
                            </a>
                        </li>
                        {/* nav item */}
                        <li className="nav-item">
                        <a className={`nav-link ${path === '/quotationlist' ? 'active' : ''}`} href="/quotationlist">
                        <i className="feather-icon icon-map-pin me-2" />
                                Quotation Request List
                            </a>
                        </li>
        
                    </ul>
                    <hr className="my-6" />
                    <div>
                        {/* nav  */}
                        <ul className="nav flex-column nav-pills nav-pills-dark">
                            {/* nav item */}
                            <li className="nav-item">
                            <button onClick={handleLogout} className="nav-link">
                                <i className="feather-icon icon-log-out me-2" />
                                Log out
                            </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Aside;
