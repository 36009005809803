import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Componets/Home/Home';
import Product from './Componets/Product/Product';
import Productdetails from './Componets/Product/Productdetails';
import Signin from './Componets/Auth/Signin';
import Signup from './Componets/Auth/Signup';
import QuoteRequest from './Componets/Quoterequest/QuoteRequest';
import RequestItem from './Componets/RequestItem/RequestItem';
import Cart from './Componets/Cart/Cart';
import Checkout from './Componets/Checkout/Checkout';
import OrderSuccess from './Componets/Order/OrderSuccess';
import About from './Componets/About/About';
import Myorder from './Componets/Dashboard/Myorder';
import Setting from './Componets/Dashboard/Setting';
import Address from './Componets/Dashboard/Address';
import Blank from './Componets/Product/Blank';
import ComparisonPage from './Componets/Comparison/ComparisonPage';
import useInactivityTimer from './Hooks/useInactivityTimer';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import QuotationList from './Componets/Dashboard/QuotationList';
import AuthProtectRoute from './ProtectedRoute/AuthProtectRoute';
import ShipingAddress from './Componets/Dashboard/ShipingAddress';
import OrderDetails from './Componets/Dashboard/OrderDetails';
import WalletHistory from './Componets/Dashboard/WalletHistory';
import QuotationDetails from './Componets/Dashboard/QuotationDetails';
import Loader from './Componets/Loader';
import ForgotPassword from './Componets/Auth/ForgotPassword';
import ResetPassword from './Componets/Auth/ResetPassword';

function App() {
  useInactivityTimer();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:categorySlug" element={<Product />} />
      <Route path="/:categorySlug/:subCategorySlug" element={<Product />} />
      <Route path="/shop" element={<Product />} />
      <Route path="/product/:productSlug" element={<Productdetails />} />

      <Route path="/signin" element={<AuthProtectRoute><Signin /></AuthProtectRoute>} />
      <Route path="/signup" element={<AuthProtectRoute><Signup /></AuthProtectRoute>} />  
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/quote-request" element={<QuoteRequest />} />
      <Route path="/requestitem" element={<RequestItem />} />
      <Route path="/order-success/:orderNumber" element={<OrderSuccess />} />
      <Route path="/order-details/:orderNumber" element={<OrderDetails />} />
      <Route path="/about" element={<About />} />
      <Route path="/blank" element={<Blank />} />
      <Route path="/comparison" element={<ComparisonPage />} />
      {/* Protected Routes */}
      <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
      <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
      <Route path="/myorder" element={<ProtectedRoute><Myorder /></ProtectedRoute>} />
      <Route path="/WalletHistory" element={<ProtectedRoute><WalletHistory /></ProtectedRoute>} />
      <Route path="/setting" element={<ProtectedRoute><Setting /></ProtectedRoute>} />
      <Route path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
      <Route path="/shipping-address" element={<ProtectedRoute><ShipingAddress /></ProtectedRoute>} />
      <Route path="/quotationlist" element={<ProtectedRoute><QuotationList></QuotationList></ProtectedRoute>} />
      <Route path="/quotation-details/:quotationId" element={<ProtectedRoute><QuotationDetails/></ProtectedRoute>} />
    </Routes>
  );
}

export default App;
